export default [
    {
        label: '控制台',
        icon : 'md-stats',
        name : 'stats',
        to   : '/',
        auth : false
    },
    {
        label: '页面图片',
        icon : 'md-photos',
        name : 'ad',
        auth: [ 'Ad' ],
        children: [
            { label: '所有页面', name: 'ad', to: '/ad', auth: [ 'Ad', 'index' ] },
            { label: '首页设置', name: 'ad-module', to: '/ad/module/0', auth: [ 'Ad', 'index' ] }
        ]
    },
    {
        label: '规则说明',
        icon : 'md-text',
        name : 'rule-explain',
        to   : '/rule-explain',
        auth: [ 'RuleExplain' ]
    },
    {
        label: '用户管理',
        icon : 'md-contact',
        name : 'user',
        auth: [ 'User' ],
        children: [
            { label: '用户管理', name: 'user', to: '/user', auth: [ 'User', 'index' ] },
            { label: '用户提现', name: 'user-withdraw', to: '/user-withdraw', auth: [ 'UserWithdraw', 'index' ] },
            { label: '用户反馈', name: 'user-feedback', to: '/user-feedback', auth: [ 'UserFeedback', 'index' ] }
        ]
    },
    {
        label: '会员卡管理',
        icon : 'md-card',
        name : 'member-card',
        auth: [ 'MemberCard,MemberCardChannel,MemberCardOnline,MemberCardOnlineShare' ],
        children: [
            { label: '会员卡管理', name: 'member-card', to: '/member-card', auth: [ 'MemberCard', 'index' ] },
            { label: '激活记录', name: 'member-card-records', to: '/member-card-records', auth: [ 'MemberCard', 'record' ] },
            { label: '渠道管理', name: 'member-card-channel', to: '/member-card-channel', auth: [ 'MemberCardChannel', 'index' ] },
            { label: '线上会员卡管理', name: 'member-card-online', to: '/member-card-online', auth: [ 'MemberCardOnline', 'index' ] },
            { label: '线上会员卡购买记录', name: 'member-card-online-records', to: '/member-card-online-records', auth: [ 'MemberCardOnline', 'record' ] },
            { label: '线上会员卡分享赚', name: 'member-card-online-share', to: '/member-card-online-share', auth: [ 'MemberCardOnlineShare', 'index' ] }
        ]
    },
    {
        label: '题库管理',
        icon : 'md-book',
        name : 'question-bank',
        auth: [ 'QuestionBank,QuestionSection,QuestionSubject,QuestionCategory' ],
        children: [
            { label: '题库管理', name: 'question-bank', to: '/question-bank', auth: [ 'QuestionBank', 'index' ] },
            { label: '题库分类', name: 'question-category', to: '/question-category', auth: [ 'QuestionCategory', 'index' ] },
            { label: '科目管理', name: 'question-subject', to: '/question-subject', auth: [ 'QuestionSubject', 'index' ] },
            { label: '章节管理', name: 'question-section', to: '/question-section', auth: [ 'QuestionSection', 'index' ] },
            { label: '答题记录', name: 'question-bank-records', to: '/question-bank-records', auth: [ 'QuestionBank', 'record' ] }
            
        ]
    },
    {
        label: '试卷管理',
        icon : 'md-albums',
        name : 'exam',
        auth: [ 'Exam,ExamQuestionBank' ],
        children: [
            { label: '试卷管理', name: 'exam', to: '/exam', auth: [ 'Exam', 'index' ] },
            { label: '答题记录', name: 'exam-records', to: '/exam-records', auth: [ 'Exam', 'record' ] }
        ]
    },
    {
        label: '视频管理',
        icon : 'md-film',
        name : 'video',
        auth: [ 'Video,VideoCategory' ],
        children: [
            { label: '视频管理', name: 'video', to: '/video', auth: [ 'Video', 'index' ] },
            { label: '视频分类', name: 'video-category', to: '/video-category', auth: [ 'VideoCategory', 'index' ] },
            { label: '视频播放记录', name: 'video-records', to: '/video-records', auth: [ 'Video', 'record' ] }
            
        ]
    },
    {
        label: '资讯管理',
        icon : 'md-paper',
        name : 'news',
        auth: [ 'News' ],
        children: [
            { label: '资讯管理', name: 'news', to: '/news', auth: [ 'News', 'index' ] }
        ]
    },
    {
        label: '学校管理',
        icon : 'md-podium',
        name : 'university',
        auth: [ 'University,UniversityMajor' ],
        children: [
            { label: '学校管理', name: 'university', to: '/university', auth: [ 'University', 'index' ] }
        ]
    },
    {
        label: '系统设置',
        icon : 'md-settings',
        name : 'settings',
        auth: [ 'Config, Admin, AuthRole'],
        children: [
            { label: '参数配置', name: 'config', to: '/config', auth: [ 'Config', 'index' ] },
            { label: '管理员', name: 'admin', to: '/admin', auth: [ 'Admin', 'index' ] },
            { label: '角色权限', name: 'auth-role', to: '/auth-role', auth: [ 'AuthRole', 'index' ] }
        ]
    },

    
]