<template>
    <Menu
        class="sidebar animated slideInLeft"
        :class="{ 'sidebar-fixed': scroll > 60 }"
        :active-name="active"
        theme="light"
        width="200px"
        accordion
        :open-names="[ open ]"
    >
        <template v-for="(item, index) in data" v-if="Auth(item.auth)">
            <template v-if="!item.children || !item.children.length">
                <MenuItem :name="item.name" :to="item.to" @click.native="$emit('on-click', item)">
                    <Icon :type="item.icon" ></Icon> {{ item.label }}
                </MenuItem>
            </template>
            <template v-else>
                <Submenu :name="item.name">
                    <template  slot="title">
                        <Icon :type="item.icon"></Icon> {{ item.label }}
                    </template>
                    <MenuItem
                        :name="child.name"
                        :to="child.to"
                        v-for="child in item.children"
                        :key="child.name"
                        @click.native="$emit('on-click', child)"
                        v-if="Auth(child.auth)"
                    >
                        {{ child.label }}
                    </MenuItem>
                </Submenu>
            </template>
        </template>
    </Menu>
</template>

<script>
    import MenuData from '@/utils/menu'

    export default {
        data()
        {
            return {
                data  : MenuData,
                active: MenuData[0].name,
                open  : MenuData[0].name,
                scroll: 0
            }
        },
        created()
        {
            this.initMenu()
            window.addEventListener('scroll', this.onScroll)
        },
        methods: {
            initMenu()
            {
                if (this.$route.name == 'index')
                    return

                group:
                for (var group of this.data)
                {
                    if (group.children)
                    {
                        for (var item of group.children)
                        {
                            if (item.name == this.$route.name)
                            {
                                this.active = item.name
                                this.open   = group.name
                                break group
                            }
                        }
                    }
                    else
                    {
                        if (group.name == this.$route.name)
                        {
                            this.active = group.name
                            this.open   = group.name
                            break
                        }
                    }
                }
            },
            onScroll()
            {
                if (document.documentElement && document.documentElement.scrollTop)
                    this.scroll = document.documentElement.scrollTop
                else if (document.body)
                    this.scroll = document.body.scrollTop
            }
        }
    }
</script>

<style>
    .sidebar {
        height: 100%;
        max-height: 100vh;
        overflow-y: scroll;
    }

    .sidebar-fixed {
        overflow: scroll;
        position: fixed;
        top: 0;
    }
</style>