<template>
    <Layout class="vh100-min" v-if="$store.getters.admin">
        <Header class="pd-0">
            <navigation-bar />
        </Header>

        <Layout>
            <Sider hide-trigger>
                <sidebar-menu />
            </Sider>
            <Layout class="pd-lr pb">
                <breadcrumb class="pd-tb-sm"/>
                <Content class="pd bg-white">
                    <router-view />
                </Content>
                <!-- <footer class="pd">
                    <copyright class="text-center text-xs text-grey"/>
                </footer> -->
            </Layout>
        </Layout>
        <BackTop />
    </Layout>
</template>

<script>
    import NavigationBar from '@/components/layout/NavigationBar'
    import SidebarMenu   from '@/components/layout/SidebarMenu'
    import Breadcrumb    from '@/components/layout/Breadcrumb'
    import Copyright     from '@/components/layout/Copyright'

    export default {
        components: {
            NavigationBar,
            SidebarMenu,
            Breadcrumb,
            Copyright
        },
        created()
        {
            this.refreshToken()
        },
        methods: {
            refreshToken()
            {
                this.$http.get('/login/refreshToken')
                    .then(res => {
                        this.$store.dispatch('login', res)
                    })
            }
        }
    }
</script>