<template>
    <section>
        <Menu mode="horizontal" theme="primary" :active-name="$route.name" @on-select="selectMenu">
            <router-link to="/" replace class="fl ml-sm flex flex-align-center">
                <img
                    src="@/assets/logo.jpg"
                    class="block radius"
                    height="38"
                />
                <h3 class="text-white ml-sm">辰优学后台管理</h3>
            </router-link>

            <div class="fr mr">
                <MenuItem name="profile" to="/profile">
                    <Icon type="md-contact"/> 您好，{{ $store.getters.admin.username }}
                </MenuItem>
                <MenuItem name="exit-full-screen" v-if="full_screen">
                    <Icon type="md-contract"></Icon> 退出全屏
                </MenuItem>
                <MenuItem name="full-screen" v-else>
                    <Icon type="md-expand"></Icon> 全屏显示
                </MenuItem>
                <MenuItem name="logout">
                    <Icon type="md-log-out"/> 退出登录
                </MenuItem>
            </div>
        </Menu>
    </section>
</template>

<script>
    import SidebarMenu from '@/components/layout/SidebarMenu'
    
    export default {
        components: {
            SidebarMenu
        },
        data()
        {
            return {
                drawer : false,
                full_screen: false
            }
        },
        methods: {
            selectMenu(name)
            {
                this.drawer = false

                switch (name)
                {
                    case 'full-screen':
                        return this.fullScreen()

                    case 'exit-full-screen':
                        return this.exitFullScreen()

                    case 'logout':
                        return this.$Modal.confirm({
                                    title    : '退出登录',
                                    content  : '确认要退出登录吗？',
                                    closable : true,
                                    onOk: () => {
                                        this.$store.dispatch('logout')
                                        this.$router.replace('/login')
                                    }
                                })

                    default:

                        break
                }
            },
            fullScreen()
            {
                // W3C
                if (document.documentElement.requestFullscreen)
                    document.documentElement.requestFullscreen()

                // FireFox
                else if (document.documentElement.mozRequestFullScreen)
                    document.documentElement.mozRequestFullScreen()

                // Chrome
                else if (document.documentElement.webkitRequestFullScreen)
                    document.documentElement.webkitRequestFullScreen()

                // IE11
                else if (elem.msRequestFullscreen)
                    elem.msRequestFullscreen()

                this.full_screen = true
            },
            exitFullScreen()
            {
                if (document.exitFullscreen)
                    document.exitFullscreen()

                else if (document.mozCancelFullScreen)
                    document.mozCancelFullScreen()

                else if (document.webkitCancelFullScreen)
                    document.webkitCancelFullScreen()

                else if (document.msExitFullscreen)
                    document.msExitFullscreen()

                this.full_screen = false
            }
        }
    }
</script>